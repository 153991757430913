.MuiTable-root{
    button {
        border: none;
        background: transparent;
        outline: none;
        margin: 0 5px;

        &:hover {
            cursor: pointer;
        }
    }

    a {
        margin: 5px 0;
    }

    thead {
        position: relative;
        z-index: 0;
    }
}

.fix-icon-color {
    .a {
        fill: #666;
        stroke: #666;
    }
}
.custom-table-list {
    table {
      tbody {
        tr {
          &.MuiTableRow-root {
            border-bottom: 1px solid #ababad !important;
            // border: 0px !important;
            height: auto !important;

            td {
              border: 0px !important;
            }
          }
        }
      }
    }

    .custom-table-sale-return {
      table {
        width:100% !important;
      }
    }

    &.v-top {
      tr {
        vertical-align: top !important;
      }
    }
  }

  .item-table-header {
    thead {
      background-color: #fff !important;
      tr {
        th {
        padding: 10px 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid rgb(207, 207, 207);
        }
      }
    }
    tbody {
      background-color: #fff !important;
      tr {
        td {
        padding: 10px 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid rgb(207, 207, 207) ;
        }
      }
    }
  }

  .remove-icon {
    background-color: rgba(241, 63, 122, 0.89);
    border-radius: 50%;
    width:20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    :hover {
    background-color: rgba(214, 13, 80, 0.89);
    border-radius: 50%;
    width:20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    }
    span {
      font-size: 12px;
      padding: auto;
      color: #fff;
    }
  }

  .stock_table {
    tr {
      th {
        border: 1px solid rgb(207, 207, 207);
        padding: 5px 10px;
        text-align: center;
      }
      td {
        border: 1px solid rgb(207, 207, 207);
        padding: 5px 10px;
        text-align: center;
      }
    }
  }

  .status-width {
    max-width: 250px !important;
  }

  .status-width-long {
    max-width: 340px !important;
  }

  .border-status {
    border-left: 4px solid rgb(255, 173, 22) !important;
    padding-left: 10px;
  }

  .table-skip {
    tr {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      td{
        padding: 10px 30px;
      }
    }
  }

  .horizontal-scroll-close {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  // .MuiTable-root {
  //   td {
    
  //   }
  // }

  // .sale-order-list {
  //   .MUIDataTable-responsiveBase-26{
  //     .MUIDataTable-tableRoot-25 {
  //       .MuiTableBody-root {
  //         .MuiTableRow-root {
  //            td {
  //             width:220px;
  //             min-width: 220px !important;
  //             &:last-child {
  //               background-color: aqua !important;
  //               width:320px;
  //               min-width: 320px !important;
  //             }
  //            }
  //            td:first-child {
  //             width:40px;
  //             min-width: 40px !important;
  //             max-width: 40px;
  //             background-color: #fff;
  //            }
  //         }
  //       }
  //     }

  //   }
  // }

  .sale-order-list {
    div{
      table{
        
        thead {
          tr{
            th {
              span{
                width:100%;
                button{
                  width:100%;
                  span{
                  padding: 0px !important;
                  display:block !important;
                  }
                  padding: 0px !important;
                }
              }
              margin: 0px !important;
              width:180px;
              min-width: 180px !important;
              text-align: left !important;
              &:last-child {
                width:200px;
                min-width: 200px !important;
              }
              &:first-child {
                width:40px;
                min-width: 40px !important;
                max-width: 40px;
                // background-color: #fff;
                }
          }
        }
        }
        tbody {
          tr{
            td {
              width:180px;
              min-width: 180px !important;
              &:last-child {
                width:200px;
                min-width: 200px !important;
              }
              &:first-child {
                width:40px;
                min-width: 40px !important;
                max-width: 40px;
                background-color: #fff;
                }
            }
          }
        }
      }
    }
  }

  .customer-list {
    div{
      table{
        
        thead {
          tr{
            th {
              span{
                width:100%;
                padding: 0px !important;
                display:block !important;
                button{
                  width:100%;
                  span{
                  padding: 0px !important;
                  display:block !important;
                  }
                  padding: 0px !important;
                }
              }
              margin: 0px !important;
              width:180px;
              min-width: 180px !important;
              text-align: left !important;
              &:last-child {
                width:200px;
                min-width: 200px !important;
              }
              // &:first-child {
              //   width:40px;
              //   min-width: 40px !important;
              //   max-width: 40px;
              //   // background-color: #fff;
              //   }
          }
        }
        }
        tbody {
          tr{
            td {
              width:180px;
              min-width: 180px !important;
              &:last-child {
                width:200px;
                min-width: 200px !important;
              }
              // &:first-child {
              //   width:40px;
              //   min-width: 40px !important;
              //   max-width: 40px;
              //   background-color: #fff;
              //   }
            }
          }
        }
      }
    }
  }

  .custom-table-list {
   div{
     div{
       div{
         div{
           div{
             table{
               thead{
                 tr{
                   th{
                     span{
                       div{
                         font-size: 16px !important;
                         font-weight: normal;
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       }
     }
   }
  }

  .history-border {
    border-top: 1px solid rgb(230, 230, 230);
  }

  .history-border:last-child {
    border-bottom: 1px solid rgb(230, 230, 230);
  }

  .department-table-head {
      th{
        background-color: transparent !important;
        color: #565553 !important;
        font-weight: normal !important;
        font-size: 12px;
      }
      tbody{
        border-top:1px solid #ccc !important;
      }
      tfoot{
        border-top:1px solid #ccc !important;
      }
    }
     
    .left-select-box {
      div{
        table{
          tbody{
            tr{
              td:first-child{
                z-index: 10 !important;
              }
            }
          }
        }
      }
    }

    .MUIDataTableSelectCell-fixedLeft-59 {
      z-index: 10 !important;
    }