@import "~bootstrap/dist/css/bootstrap.min.css";

/* ICONS */
@import "~material-design-icons/iconfont/material-icons.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

@import "~react-toastify/dist/ReactToastify.min.css";

/* FONTS */
@import 'fonts/hk-grotesk';


/* ABSTRACTS */
@import 'abstracts/variables';
@import 'abstracts/mixins';


/* BASE */
@import 'base/typography';
@import 'base/colors';
@import 'base/reset';


/* LAYOUT */
@import 'layout/header';
@import 'layout/menu-left';


/* COMPONENTS */
@import 'components/button';
@import 'components/card';
@import 'components/form';
@import 'components/sticky-page-header';
@import 'components/input';
// @import 'components/date-range-picker';
@import 'components/_date-range-picker.scss';
@import 'components/table';
@import 'components/label';
@import 'components/group-button';


/* PAGES */
@import 'pages/sign-in';
@import 'pages/page-not-found';

@import 'pages/dashboard/dashboard';

.MuiTable-root {
    th {
        background-color: #EAEDF2;
        font-size: 14px;
        font-family: $font-family;
        font-weight: bold;
        line-height: 17px;
    }

    td {
        font-family: $font-family;
        font-size: 14px;
        line-height: 17px;
    }
}

.Toastify__toast-body {
    color: #fff;
}

.dropdown-toggle {
    z-index: 0 !important;
}

.control-label {
    display: inline-block;
    float: left;
    margin-right: 3%;
    text-align: right;
    width: 22%;
    clear: both;
}

.invalid-feedback {
    padding-left: 25%;
    clear: both;
}

form {
    width: 100%;
}

.form-input {
    width: 75%;
    float: right;
    margin-bottom: 5px;

    &.no-border {
        padding: 0;
        border: none;
    }

    &.has-margin {
        margin-bottom: 30px;
    }
}

.form-check {
    label {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (min-width: 992px) {
    .modal-dialog {
        margin: 4.75rem auto;
    }
}

.table {
    thead {
        th {
            background-color: $color-primary;
            color: #fff;
            border-bottom: none;
        }
    }

    tbody {
        td {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.theme-input-color {
    color: $color-second;
}

.form-input-group {
    width: 60%;
    float: left;
}

.form-input-btn {
    float: left;
    width: 12%;
    margin-left: 3% !important;
}

.Toastify__toast-container {
    span {
        color: #fff;
    }
}

div[role=tooltip] {
    z-index: 999 !important;
}
.report-header {
    font-size: 1.5em;
    font-weight: 600;
}

body {
    width: 100% !important;
    display: inline-block !important;
    min-width:480px !important;
    object-fit: contain !important;
}

input[type=number]::-webkit-inner-spin-button, 

input[type=number]::-webkit-outer-spin-button { 

  -webkit-appearance: none; 

  margin: 0; 

}