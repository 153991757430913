﻿/* RESPONSIVE -> It is also necessary to change at AuthDataProvider.jsx*/
$breakpoints: ( 
    xs: 576px, 
    sm: 768px, 
    md: 992px, 
    lg: 1200px, 
    xl: 1366px
);

//Respond above
@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (min-width: $breakpoint-value) {
            @content;
        }
    }
}

//Respond below
@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }
}

//Respond between
@mixin respond-between($lower, $upper) {
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    }
}
/* END RESPONSIVE */





/* MAX LINE */
@mixin max-lines($lines: 2, $line-height: 1.2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    line-height: $line-height;
    max-height: ($line-height * $lines) + "px";
}
/* END MAX LINE */