.dashboard-page {
    @media (min-width: 992px) {
    .recent-activities {
        margin-right: 10px;
    }
    .delivery-chart {
        margin-left: 10px;
    }
    }
}

.dashoard-data {
    background-color: #f5fafe;
    // border-bottom: 1px solid rgb(163, 163, 163) !important;
    .dashboard-data-card {
        // border: 1px solid bisque;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 10px !important;
        border: 1px solid rgb(163, 163, 163) !important;
        .dashboard-data-num {
            font-size: 40px;
            color: #2e86aa;
        }
    }
}

.width-adjust-time-picker {
    min-width: 240px;
}