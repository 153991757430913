.signin {
    display: flex;
    .cover {
        max-width: 480px;
        width: 100%;
        background: transparent url('../../img/background/truck.png') 0% 0% no-repeat padding-box;
        background-size: cover;
        position: relative;
        @include respond-below('lg') {
            display: none;
        }
        &__text {
            position: absolute;
            margin: auto 45px;
            color: white;
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0;
            font-weight: bold;
            bottom: 140px;
        }
        &__footer {
            position: absolute;
            bottom:0;
            right: 0;
        }
    }
    .login {
        width: 100%;
        display: flex;
        flex-direction: column;
        .form-title {
            text-align: left;
            font-size: 36px;
            color: black;
            margin: 16px 0;
        }
        .form-title-inner {
            text-align: left !important;
            font-size: 16px;
            color: black;
            margin: 16px 0;
            text-align: center;
            padding: 0px;
        }
        .form-title-bg {
            padding: 20px;
            background-color: rgba(201, 201, 201, 0.514);
            border-radius: 10px;
            margin: 16px 0;
            }
        .form-description {
            font-size: 18px;
            text-align: left;
            margin: 16px 0;
            color: black;
        }
        .form-wrapper {
            max-width: 368px;
            margin: 0 auto;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center; 
        }
    }
    .top-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-below('sm') {
            flex-direction: column;
        }
        .logo {
            margin: 32px 56px;
            img {
                width: 174px;
            }
        }
        .signup-acc {
            display: flex;
            align-items: inherit;
            margin: 0 32px;
            div {
                margin-right: 16px;
                font-size: 16px;
            };
        }
    }
}

.resetPass {
    font-size: 14px;
    color: #2e86aa;
    cursor: pointer;
}