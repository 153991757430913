.control-label {
	font-size: 14px;
	line-height: 22px;
	color: #101820;
}

.form-control:focus {   
	border-color: $color-primary;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($color-primary, 0.6);
	outline: 0 none;
}

.form-check {
	padding-left: 0;

	input[type=checkbox], input[type=radio] {
		margin-right: 5px;
	}

	label {
		margin-right: 5px;
	}
}

/* Dropdown */

.dropdown {
	display: inline-block;
	position: relative;
  }
  
  .dd-button {
	display: inline-block;
	border: 1px solid gray;
	border-radius: 4px;
	padding: 10px 30px 10px 20px;
	background-color: #ffffff;
	cursor: pointer;
	// white-space: nowrap;
  }

  .dd-button-rr {
	display: inline-block;
	border: 1px solid gray;
	border-radius: 4px;
	padding: 5px 10px;
	background-color: #565553;
	cursor: pointer;
	// white-space: nowrap;
  }
  
  .dd-button:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid black;
  }
  
  .dd-button:hover {
	background-color: #f5f5f5;
  }

  .dd-button-rr:hover {
	background-color: #444444;
  }
  
  
  .dd-input {
	display: none;
  }
  
  .dd-menu {
	position: absolute;
	right: 0;
	top: 100%;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 0;
	margin: 2px 0 0 0;
	box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
	background-color: #ffffff;
	list-style-type: none;
  }
  
  .dd-input + .dd-menu {
	display: none;
  } 
  
  .dd-input:checked + .dd-menu {
	display: block;
	z-index: 50;
  } 
  
  .dd-menu li {
	padding: 10px 20px;
	cursor: pointer;
	white-space: nowrap;
  }
  
  .dd-menu li:hover {
	background-color: #f6f6f6;
  }
  
  .dd-menu li a {
	display: block;
	margin: -10px -20px;
	padding: 10px 20px;
  }
  
  .dd-menu li.divider{
	padding: 0;
	border-bottom: 1px solid #cccccc;
  }

  .summany-right {
	  border-left: 1px solid #ccc;
	  width: 50px;
  }

  .border-left-sum {
	border-left: 1px solid #ccc;
  }

  .saledata {
	  background-color: #f5fafe;
	  border: 1px solid #2e86aa;
	  border-left: 5px solid #2e86aa;
	  
  }

  .circleSpan {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #2e86aa;
}

.number-wrap  {
	border: 1px solid #2e86aa;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: #2e86aa;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
}

.number-wrap-muted {
		border: 1px solid #ccc;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		color: #ccc;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 5px;
}
.card-style {
	background-color: #FFFFFF;
	padding: 30px 10px 20px 10px;
	border-radius: 10px;
}

.border-left-tip {
	border-left: 1px solid #ccc;
	padding: 0px 10px;
}

.warehouse-card {
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	position: relative;
}

.warehouse-card:hover {
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
	position: relative;
}

.warehouse-card-bottom {
	position: absolute;
	right: 15px;
	bottom : 5px;
}

.pad-241  {
	padding-left: 241px !important;
}

.form-border {
	border:1px solid #D8D9D9;
	
}

.tab-style-sale {
	cursor: default;
}

.custom-input-dimension {
	border: 0px solid #ccc !important;
	min-width: 10px !important;
	max-width: 120px !important;
}

.option-select {
	background-color: #2e86aa !important;
	z-index: 30;
}

.customer-option{
	z-index: 31 !important;
}

.pac-container {
	z-index: 1320 !important;
}