.custom-date-range-picker-component {
    .custom-input-component .custom-input input {
        color: #101820 !important;
        background-color: #EAEDF2 !important;

        ::placeholder {
            color: #101820 !important;
        }
    }

    .custom-input-component.secondary .MuiInputBase-adornedEnd > div {
        // z-index: 1;
    }
}

.custom-date-range-picker {
    overflow-x: auto;
    .div {
        overflow-x: auto;
        .div{
            overflow-x: auto;
            // position: absolute !important;
        }
    }

    div[role="presentation"] {
        position: absolute !important;
        .MuiMenu-paper {
            position: fixed !important;
            left: auto !important;
            top: auto !important;
        }
    }
}