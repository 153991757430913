﻿/* FONT */
$font-family: 'hk_groteskregular', Verdana, sans-serif !important;
$font-family-regular: 'hk_groteskregular', Verdana, sans-serif !important;
$font-family-medium: 'hk_groteskmedium', Verdana, sans-serif !important;
$font-family-bold: 'hk_groteskbold', Verdana, sans-serif !important;
$font-family-semibold: 'hk_grotesksemibold', Verdana, sans-serif !important;

$font-size-21: 21px;
$font-size-18: 18px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-12: 12px;
/* END FONT */



/* COLORS */
$color-primary:  #2e86aa;
// $color-primary: #64CCC9;
$color-secondary: #101820;
$color-dark: #75787B;
$color-success: #28a745;
$color-danger: #CF3E3E;
$color-warning: #FFA200;
$color-info: #4375CD;
$color-default: #F7F9FD;

$color-active:  #2e86aa;
// $color-active: #64CCC9;
$color-second: #565553;
// $color-second: #E89CAE;
$bg-color: #F5F6F9;

$muted: rgba(#484848, 20);
$gray: rgba(#484848, 60);
$black: #000000;
$white: #ffffff;
$transparent: transparent;

$skeleton-gray: #CCCCCC;

$facebook: #3b5998;
$whatsapp: #25D366;
$linkedin: #4875B4;
$twitter: #33CCFF;
$viber: #7360f2;
$telegram: #0088cc;

$word: #0180EF;
$excel: #00784B;
$pdf: #CD4141;
/* END COLORS */