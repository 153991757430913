﻿html, body {
    font-family: $font-family;
    font-size: $font-size-15;
    color: $color-secondary;
    // overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    display: initial;
    height: 100%;
}
#root, .App, .signin {
    height: 100%;
}

h1, h2, h3, h4, h5, h6,
p, span, label, div,
input, button, select, textarea, a
table, th, td {
    font-family: $font-family;
    font-size: $font-size-15;
    color: $color-secondary;
}

.page {
    margin-top: 65px;

    @include respond-below('lg') {
        margin-top: 121px;
    }

    @include respond-below('md') {
        margin-top: 121px;
    }

    @include respond-below('sm') {
        margin-top: 121px;
    }

    @include respond-below('xs') {
        margin-top: 121px;
    }

    /* BUTTONS */
    .MuiButton-label {
        color: $white;

        span {
            color: $white;
        }
    }
    /* END BUTTONS */
}

.fa, .fal, .far, .fas {
    font-family: "Font Awesome 5 Free" !important;
}

.brands.fa, .brands.fab, .brands.fal, .brands.far, .brands.fas {
    font-family: "Font Awesome 5 Brands" !important;
}


.font-size-21 {
    font-size: $font-size-21;
}
.font-size-12 {
    font-size: $font-size-12;
}
.font-size-14 {
    font-size: $font-size-14;
}
.font-size-18 {
    font-size: $font-size-18 !important;
}